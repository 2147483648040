<template>
  <div>
    <v-row dark class="mt-1">
      <v-col cols="2">
        <v-text-field class="ml-5" dark v-model="options.screenWidth" @blur="changeDimension" @keydown.enter.prevent="changeDimension" label="Širina (px):" />
      </v-col>
      <v-col cols="2">
        <v-text-field class="ml-5" dark v-model="options.screenHeight" @blur="changeDimension" @keydown.enter.prevent="changeDimension" label="Visina (px):" />
      </v-col>
      <v-col cols="8">
        <v-text-field class="ml-5 mr-5" readonly dark v-model="computedVisName" label="Vizualizacija:" />
      </v-col>
    </v-row>
    <v-row>
      <v-divider class="mx-8 mb-8" />
    </v-row>
    <v-row dark class="vis-tbl">
      <v-col cols="12">
        <v-data-table :headers="headers" :items="presets" hide-default-header @click:row="changeVisualization"></v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import { ref, computed } from '@vue/composition-api'
import { bus } from '../main'
import { loadPresets } from '@/lib/visualizer.js'

export default {
  setup (props) {
    const options = ref({
      screenWidth: 700,
      screenHeight: 428,
      selectedPresetIdx: 48,
      selectedVisualization: ''
    })

    const computedVisName = computed(() => {
      return `${options.value.selectedPresetIdx} - ${options.value.selectedVisualization.presetName}`
    })

    const headers = [
      { text: 'index', value: 'idx', hidden: true },
      { text: 'presetName', value: 'presetName' }
    ]

    const data = ref(loadPresets())
    const keys = Object.keys(data.value)
    const presets = keys.map(p => {
      return { presetName: p, idx: keys.indexOf(p) }
    })

    const changeDimension = () => {
      bus.$emit('changeDimensions', options)
    }

    const changeVisualization = (item, row) => {
      options.value.selectedPresetIdx = item.idx
      options.value.selectedVisualization = presets[options.value.selectedPresetIdx]
      bus.$emit('changeVisualization', item.idx)
    }

    return {
      options,
      changeDimension,
      changeVisualization,
      headers,
      presets,
      computedVisName
    }
  }
}

</script>
<style scoped>
.vis-tbl {
  margin-top: -2rem;
}
</style>
