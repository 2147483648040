<template>
  <div>
    <v-row dark class="d-flex flex-row ml-5 mt-5 mb-5">
      <v-card style="cursor: pointer" class="mr-4" max-width="150" @click.native="selectVideo(0)">
        <v-img height="92" src="@/assets/ssVisMob01.png"></v-img>
      </v-card>
      <v-card style="cursor: pointer" class="mr-4" max-width="150" @click.native="selectVideo(1)">
        <v-img height="92" src="@/assets/ssVisMob02.png"></v-img>
      </v-card>
      <v-card style="cursor: pointer" max-width="150" @click.native="selectVideo(3)">
        <v-img height="92" src="@/assets/ssVisMob04.png"></v-img>
      </v-card>
    </v-row>
  </div>
</template>

<script>
import { useVideoStore } from '@/store/videoStore'
import { storeToRefs } from 'pinia'

export default {
  setup () {
    const store = useVideoStore()
    const { screenWidth, screenHeight, selectedVideoIdx } = storeToRefs(store)

    const selectVideo = (idx) => {
      selectedVideoIdx.value = idx
    }

    return {
      screenWidth,
      screenHeight,
      selectVideo
    }
  }
}
</script>

<style>
</style>
