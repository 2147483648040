import butterchurnPresets from 'butterchurn-presets'

const loadPresets = () => butterchurnPresets.getPresets()

const loadPreset = (settings) => {
  const presets = loadPresets()
  const presetKeys = Object.keys(presets)
  settings.value.presetName = presetKeys[settings.value.selectedPresetIndex]
  return presets[settings.value.presetName]
}

const connectMicAudio = (settings, sourceNode, audioContext) => {
  audioContext.resume()

  const gainNode = audioContext.createGain()
  gainNode.gain.value = 1.25
  sourceNode.connect(gainNode)

  settings.value.visualizer.connectAudio(gainNode)
}

export { loadPresets, loadPreset, connectMicAudio }
