import { defineStore } from 'pinia'

export const useAnimationStore = defineStore('animationStore', {
  state: () => ({
    settings: {
      screenWidth: 700,
      screenHeight: 428,
      animation_xMin: 0,
      animation_yMin: 0,
      animation_xMax: 0,
      animation_yMax: 0,
      canvas: undefined,
      ctx: undefined,
      selectedAnimation: {},
      selectedAnimationId: 0,
      animationVelocity: 10,
      animationType: 'loop',
      shapeColor: '#ffffff',
      shape: {
        xPos: 0,
        yPos: 0
      },
      shapeWidth: 39,
      shapeHeight: 428,
      animations: [],
      ddAnimation: { text: '', value: '' },
      ddAnimationType: { text: '', value: '' },
      play: true
    }
  }),
  getters: {},
  actions: {}
})
