<template>
  <v-row justify="center">
    <v-dialog v-model="settings.dialogOpen" max-width="300px">
      <div class="dialog">
        <v-color-picker canvas-height="100" mode="hexa" v-model="settings.color"></v-color-picker>
        <div justify="center">
          <v-btn depressed class="mt-1 btn-select" @click="selectColor">
            Odaberi
          </v-btn>
        </div>
      </div>
    </v-dialog>
  </v-row>
</template>

<script>
import { ref } from '@vue/composition-api'
import { bus } from '@/main'

export default {
  setup () {
    const settings = ref({
      dialogOpen: true,
      color: undefined
    })

    const selectColor = () => {
      bus.$emit('selectColor', settings.value.color.hex)
    }

    return {
      settings,
      selectColor
    }
  }
}
</script>

<style scoped>
.dialog {
  background-color: white;
  padding: 0.2rem;
}

.btn-select {
  width: 100%;
}
</style>
