<template>
  <v-form>
    <div class="d-flex flex-column">
      <div class="d-flex justify-center mb-5">
        <v-radio-group row v-model="selectedMode">
          <v-radio v-for="mode in modes" :key="mode.label" :value="mode" :label="mode.label" />
        </v-radio-group>
        <v-switch class="switch" v-model="fullscreen" @change="setFullscreen" :ripple="false" label="Fullscreen"></v-switch>
      </div>
      <!-- <v-divider class="mx-8 mb-8" /> -->
      <v-tabs-items background-color="transparent" class="mx-8" v-model="selectedMode.id">
        <v-tab-item value="visualization">
          <visualization-config :mode="selectedMode"></visualization-config>
        </v-tab-item>
        <v-tab-item value="animation">
          <animation-config :mode="selectedMode"></animation-config>
        </v-tab-item>
        <v-tab-item value="video">
          <video-config :mode="selectedMode"></video-config>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </v-form>
</template>
<script>
import { ref, watch, onMounted } from '@vue/composition-api'
import AnimationConfig from './AnimationConfig.vue'
import VisualizationConfig from './VisualizationConfig.vue'
import VideoConfig from './VideoConfig.vue'
import { bus } from '@/main'

export default {
  components: {
    AnimationConfig,
    VisualizationConfig,
    VideoConfig
  },
  setup () {
    // radio group settings
    const modes = ref([
      { label: 'Vizualizacija', id: 'visualization' },
      { label: 'Animacija', id: 'animation' },
      { label: 'Video', id: 'video' }
    ])
    const selectedMode = ref(modes.value[0])

    // fullscreen logic
    const fullscreen = ref(false)
    const fullscreenchange = () => {
      fullscreen.value = !!document.fullscreenElement
    }

    watch(selectedMode, (nv, ov) => {
      bus.$emit('setApplicationType', (nv.id))
    })

    onMounted(() => {
      document.addEventListener('fullscreenchange', fullscreenchange)
      // document.addEventListener('dblclick', setFullscreen)
    })

    function setFullscreen () {
      const wrapperEl = document.querySelector('.app')
      if (!wrapperEl) return
      if (!document.fullscreenElement) {
        if (wrapperEl.requestFullscreen) {
          wrapperEl.requestFullscreen()
        } else if (wrapperEl.webkitRequestFullscreen) {
          /* Safari */
          wrapperEl.webkitRequestFullscreen()
        } else if (wrapperEl.msRequestFullscreen) {
          /* IE11 */
          wrapperEl.msRequestFullscreen()
        }
      } else {
        document.exitFullscreen()
      }
    }

    return {
      modes,
      selectedMode,
      fullscreen,
      setFullscreen
    }
  }
}
</script>
<style scoped>
.switch {
  position: absolute;
  right: 30px;
}
@media screen and (max-width: 1250px) {
  .switch {
    display: none;
  }
}
</style>
