import { defineStore } from 'pinia'

export const useVideoStore = defineStore('videoStore', {
  state: () => ({
    screenWidth: 700,
    screenHeight: 428,
    selectedVideoIdx: 0
  }),
  actions: {
    changeVideo (idx) {
      this.selectedVideoIdx.value = idx
    }
  }
})
