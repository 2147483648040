<template>
  <canvas id="visCanvas" class="vis-canvas" :width="settings.screenWidth" :height="settings.screenHeight" />
</template>

<script>
import { useVisualizationStore } from '@/store/visualizationStore'
import { storeToRefs } from 'pinia'
import { ref, onMounted, onUnmounted } from '@vue/composition-api'
import butterchurn from 'butterchurn'
import { loadPreset, connectMicAudio } from '@/lib/visualizer'
import { bus } from '@/main'

export default {
  setup () {
    const store = useVisualizationStore()
    const { _screenWidth, _screenHeight, _selectedPresetIndex } = storeToRefs(store)

    const settings = ref({
      applicationType: 'visualization',
      screenWidth: _screenWidth,
      screenHeight: _screenHeight,
      selectedPresetIndex: _selectedPresetIndex,
      visualizer: undefined,
      presetName: '',
      audioContext: undefined,
      play: true
    })

    const getVisCanvas = () => {
      return document.getElementById('visCanvas')
    }

    const createVisualizer = async () => {
      if (settings.value.visualizer) {
        resetVisualization()
      }
      const canvas = getVisCanvas()
      if (!settings.value.audioContext) {
        const AudioContext = window.AudioContext || window.webkitAudioContext
        settings.value.audioContext = new AudioContext()
      }

      settings.value.visualizer = butterchurn.createVisualizer(settings.value.audioContext, canvas, {
        width: settings.value.screenWidth,
        height: settings.value.creenHeight,
        pixelRatio: window.devicePixelRatio || 1,
        textureRatio: 1
      })
      canvas.setAttribute('width', settings.value.screenWidth)
      canvas.setAttribute('height', settings.value.screenHeight)
      settings.value.visualizer.setRendererSize(settings.value.screenWidth, settings.value.screenHeight)

      const preset = loadPreset(settings)
      settings.value.visualizer.loadPreset(preset)

      const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
      // microphone can now act like any other AudioNode
      const microphone = settings.value.audioContext.createMediaStreamSource(stream)
      connectMicAudio(settings, microphone, settings.value.audioContext)
      settings.value.play = true
      render()
    }

    const render = () => {
      if (settings.value.play) {
        settings.value.visualizer.render()
        window.requestAnimationFrame(() => render())
      }
    }

    const resetVisualization = () => {
      settings.value.play = false
      settings.value.visualizer = null
    }

    bus.$on('createVisualizer', () => {
      createVisualizer()
    })

    bus.$on('changeDimensions', (options) => {
      settings.value.screenWidth = Number(options.value.screenWidth)
      settings.value.screenHeight = Number(options.value.screenHeight)

      const canvas = getVisCanvas()
      canvas.style.flex = `0 0 ${settings.value.screenWidth}px`
      canvas.style.height = `${settings.value.screenHeight}px`

      createVisualizer()
    })

    bus.$on('changeVisualization', (idx) => {
      settings.value.selectedPresetIndex = idx
      createVisualizer()
    })

    onMounted(() => {
      createVisualizer()
    })

    onUnmounted(() => {
      resetVisualization()
      settings.value.audioContext = null
    })

    return {
      settings,
      createVisualizer,
      loadPreset
    }
  }
}
</script>

<style scoped>
.vis-canvas {
  flex: 0 0 700px;
  height: 428px;
  background-color: gray !important;
}
</style>
