import { render, staticRenderFns } from "./ColorPickerDialog.vue?vue&type=template&id=ce034222&scoped=true&"
import script from "./ColorPickerDialog.vue?vue&type=script&lang=js&"
export * from "./ColorPickerDialog.vue?vue&type=script&lang=js&"
import style0 from "./ColorPickerDialog.vue?vue&type=style&index=0&id=ce034222&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ce034222",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VColorPicker } from 'vuetify/lib/components/VColorPicker';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VColorPicker,VDialog,VRow})
