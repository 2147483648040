export const defineAnimations = (settings) => {
  return [
    {
      id: 0,
      name: 'rectLeftToRight',
      movement: 'h',
      xVelocity: 1,
      yVelocity: 0,
      xPos: settings.value.screenWidth / 2,
      yPos: 0,
      shapeWidth: 39,
      shapeHeight: settings.value.screenHeight
    },
    {
      id: 1,
      name: 'rectRightToLeft',
      movement: 'h',
      xVelocity: -1,
      yVelocity: 0,
      xPos: settings.value.screenWidth / 2,
      yPos: 0,
      shapeWidth: 39,
      shapeHeight: settings.value.screenHeight
    },
    {
      id: 2,
      name: 'rectTopToBottom',
      movement: 'v',
      xVelocity: 0,
      yVelocity: 1,
      xPos: 0,
      yPos: settings.value.screenHeight / 2,
      shapeWidth: settings.value.screenWidth,
      shapeHeight: 25
    },
    {
      id: 3,
      name: 'rectBottomToTop',
      movement: 'v',
      xVelocity: 0,
      yVelocity: -1,
      xPos: 0,
      yPos: settings.value.screenHeight / 2,
      shapeWidth: settings.value.screenWidth,
      shapeHeight: 25
    }
  ]
}
