<template>
  <canvas id="animCanvas" class="anim-canvas" :width="screenWidth" :height="screenHeight" />
</template>

<script>
import { useAnimationStore } from '@/store/animationStore'
import { storeToRefs } from 'pinia'
import { ref, onMounted, onUnmounted } from '@vue/composition-api'
import { defineAnimations } from '@/lib/animationDefinitions'
import { drawRectangle } from '@/lib/animationHelpers'
import { bus } from '@/main'

export default {
  setup () {
    const store = useAnimationStore()
    const { settings } = storeToRefs(store)

    const screenWidth = ref(settings.value.screenWidth)
    const screenHeight = ref(settings.value.screenHeight)
    const xVelocity = ref(0)
    const yVelocity = ref(0)

    const getAnimCanvas = () => {
      return document.getElementById('animCanvas')
    }

    const setupAnimation = () => {
      settings.value.canvas = getAnimCanvas()
      settings.value.canvas.screenWidth = screenWidth.value
      settings.value.canvas.screenHeight = screenHeight.value
      settings.value.ctx = settings.value.canvas.getContext('2d')
    }

    const prepareAnimation = (id) => {
      clearFrame()

      settings.value.selectedAnimationId = id
      settings.value.selectedAnimation = { ...settings.value.animations.find((a) => a.id === id) }

      settings.value.shape.xPos = settings.value.selectedAnimation.xPos
      settings.value.shape.yPos = settings.value.selectedAnimation.yPos

      settings.value.animation_xMin = 0 - settings.value.selectedAnimation.shapeWidth
      settings.value.animation_yMin = 0 - settings.value.selectedAnimation.shapeHeight
      settings.value.animation_xMax = screenWidth.value
      settings.value.animation_yMax = screenHeight.value
      xVelocity.value = settings.value.selectedAnimation.xVelocity * settings.value.animationVelocity
      yVelocity.value = settings.value.selectedAnimation.yVelocity * settings.value.animationVelocity

      animate()
    }

    const animate = () => {
      clearShape()
      settings.value.shape.xPos += xVelocity.value
      settings.value.shape.yPos += yVelocity.value

      if (settings.value.animationType === 'loop') {
        if (settings.value.shape.xPos > settings.value.animation_xMax) {
          settings.value.shape.xPos = settings.value.animation_xMin
        } else if (settings.value.shape.xPos < settings.value.animation_xMin) {
          settings.value.shape.xPos = screenWidth.value
        }
        if (settings.value.shape.yPos > settings.value.animation_yMax) {
          settings.value.shape.yPos = settings.value.animation_yMin
        } else if (settings.value.shape.yPos < settings.value.animation_yMin) {
          settings.value.shape.yPos = settings.value.animation_yMax
        }
      } else if (settings.value.animationType === 'rebound') {
        if (settings.value.shape.xPos > settings.value.animation_xMax - settings.value.selectedAnimation.shapeWidth) {
          xVelocity.value *= -1
        } else if (settings.value.shape.xPos < 0) {
          xVelocity.value *= -1
        }
        if (settings.value.shape.yPos > settings.value.animation_yMax - settings.value.selectedAnimation.shapeHeight) {
          yVelocity.value *= -1
        } else if (settings.value.shape.yPos < 0) {
          yVelocity.value *= -1
        }
      }
      drawRectangle(
        settings.value.shape.xPos,
        settings.value.shape.yPos,
        settings.value.selectedAnimation.shapeWidth,
        settings.value.selectedAnimation.shapeHeight,
        settings.value.shapeColor,
        settings.value.ctx
      )
      if (settings.value.play) {
        window.requestAnimationFrame(animate)
      }
    }

    const clearFrame = () => {
      settings.value.ctx.clearRect(0, 0, screenWidth.value, screenHeight.value)
    }

    const clearShape = () => {
      settings.value.ctx.clearRect(settings.value.shape.xPos, settings.value.shape.yPos, settings.value.selectedAnimation.shapeWidth, settings.value.selectedAnimation.shapeHeight)
    }

    const recalculateAnimation = () => {
      const animation = settings.value.selectedAnimation
      if (animation.movement === 'h') {
        animation.shapeHeight = screenHeight.value
      } else if (animation.movement === 'v') {
        animation.shapeWidth = screenWidth.value
      }

      settings.value.animation_xMin = 0 - animation.shapeWidth
      settings.value.animation_yMin = 0 - animation.shapeHeight
      settings.value.animation_xMax = screenWidth.value
      settings.value.animation_yMax = screenHeight.value
    }

    bus.$on('onRecalculateAnimation', () => {
      settings.value.play = false
      recalculateAnimation()
      settings.value.play = true
    })

    bus.$on('changeAnimDimensions', () => {
      screenWidth.value = settings.value.screenWidth
      screenHeight.value = settings.value.screenHeight

      const canvas = getAnimCanvas()
      canvas.style.flex = `0 0 ${screenWidth.value}px`
      canvas.style.height = `${screenHeight.value}px`
      recalculateAnimation()
    })

    bus.$on('changeAnimation', () => {
      settings.value.play = false
      prepareAnimation(settings.value.selectedAnimationId)
      settings.value.play = true
    })

    bus.$on('changeAnimationVelocity', (value) => {
      settings.value.play = false
      settings.value.animationVelocity = value
      prepareAnimation(settings.value.selectedAnimationId)
      settings.value.play = true
    })

    onMounted(() => {
      settings.value.play = true
      settings.value.animations = defineAnimations(settings)
      setupAnimation()
      prepareAnimation(settings.value.selectedAnimationId)
    })

    onUnmounted(() => {
      settings.value.play = false
    })

    return {
      settings,
      screenWidth,
      screenHeight,
      xVelocity,
      yVelocity
    }
  }
}
</script>

<style scoped>
.anim-canvas {
  flex: 0 0 700px;
  height: 428px;
  background-color: black !important;
}
</style>
