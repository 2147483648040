<template>
  <v-app class="app">
    <div class="d-flex">
      <ai-canvas />
      <settings-area class="settings-area" />
    </div>
  </v-app>
</template>

<script>
import AiCanvas from '@/components/Canvas'
import SettingsArea from '@/components/SettingsArea'
export default {
  name: 'App',
  components: {
    AiCanvas,
    SettingsArea
  }
}
</script>
<style scoped>
.settings-area {
  flex: 1;
}
</style>
