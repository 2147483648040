<template>
  <div>
    <canvas-visualization v-if="settings.applicationType === 'visualization'" />
    <canvas-animation v-if="settings.applicationType === 'animation'" />
    <canvas-video v-if="settings.applicationType === 'video'" />
  </div>
</template>

<script>

import CanvasVisualization from '@/components/canvas/CanvasVisualization.vue'
import CanvasAnimation from '@/components/canvas/CanvasAnimation.vue'
import CanvasVideo from '@/components/canvas/CanvasVideo.vue'
import { ref } from '@vue/composition-api'
import { bus } from '@/main'

export default {
  components: { CanvasVisualization, CanvasAnimation, CanvasVideo },
  setup () {
    const settings = ref({
      applicationType: 'visualization',
      screenWidth: 700,
      screenHeight: 428,
      selectedPresetIndex: 0,
      visualizer: undefined,
      presetName: '',
      audioContext: undefined
    })

    bus.$on('setApplicationType', (type) => {
      settings.value.applicationType = type
    })

    return {
      settings
    }
  }
}
</script>

<style scoped>
.vis-canvas {
  flex: 0 0 700px;
  height: 428px;
  background-color: gray !important;
}
</style>
