const clearCanvas = (settings) => {
  settings.value.ctx.clearRect(0, 0, settings.value.screenWidth, settings.value.screenHeight)
}

const drawRectangle = (x, y, w, h, c, ctx) => {
  ctx.beginPath()
  ctx.fillStyle = c
  ctx.fillRect(x, y, w, h)
}

export {
  clearCanvas,
  drawRectangle
}
