<template>
  <div>
    <v-row dark class="mt-1">
      <v-col cols="3">
        <v-text-field class="ml-5" dark v-model="settings.screenWidth" @blur="changeDimension" @keydown.enter.prevent="changeDimension" label="Širina (px):" />
      </v-col>
      <v-col cols="3">
        <v-text-field class="ml-5" dark v-model="settings.screenHeight" @blur="changeDimension" @keydown.enter.prevent="changeDimension" label="Visina (px):" />
      </v-col>
      <v-col cols="3">
        <v-select class="ml-5 mr-5" dark :items="animationsForDropdown" item-text="text" item-value="value" v-model="settings.ddAnimation" @change="onSelectedAnimationChange" label="Animacija:" />
      </v-col>
      <v-col cols="3">
        <v-select class="ml-5 mr-5" dark :items="animationTypesForDropdown" item-text="text" item-value="value" v-model="settings.ddAnimationType" @change="onSelectedAnimationTypeChange" label="Vrsta animacije:" />
      </v-col>
    </v-row>
    <v-row dark class="mt-1">
      <v-col cols="3">
        <v-text-field class="ml-5 mr-5" type="number" dark v-model="settings.animationVelocity" @blur="onChangeVelocity" @keydown.enter.prevent="onChangeVelocity" label="Brzina elementa:" />
      </v-col>
      <v-col cols="3">
        <v-text-field class="ml-5 mr-5" :color="settings.shapeColor" dark v-model="settings.shapeColor" label="Boja elementa:" @click="showColorPicker"></v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field :disabled="settings.selectedAnimation.movement === 'v'" class="ml-5 mr-5" dark v-model="settings.shapeWidth" @blur="onChangeShapeDimension" @keydown.enter.prevent="onChangeShapeDimension" label="Širina elementa:*"></v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field :disabled="settings.selectedAnimation.movement === 'h'" class="ml-5 mr-5" dark v-model="settings.shapeHeight" @blur="onChangeShapeDimension" @keydown.enter.prevent="onChangeShapeDimension" label="Visina elementa:*"></v-text-field>
      </v-col>
    </v-row>
    <v-row dark class="mt-1">
      <v-col class="ml-5">
        <small>* Kod animacija gdje se element kreće horizontalno, visina elementa je jednaka visini ekrana,
          dok je kod animacija gdje se element kreće vertikalno širina elementa jednaka širini ekrana.</small>
      </v-col>
    </v-row>
    <color-picker-dialog v-if="dialogOpen" />
  </div>
</template>

<script>
import { useAnimationStore } from '@/store/animationStore'
import { storeToRefs } from 'pinia'
import { ref, onMounted } from '@vue/composition-api'
import { bus } from '@/main'
import { defineAnimations } from '@/lib/animationDefinitions'
import ColorPickerDialog from '../components/ColorPickerDialog.vue'

export default {
  components: {
    ColorPickerDialog
  },
  setup (props) {
    const store = useAnimationStore()
    const { settings } = storeToRefs(store)

    const dialogOpen = ref(false)
    const animationsForDropdown = ref([])
    const animationTypesForDropdown = ref([])

    const setupControlPanel = () => {
      settings.value.animations.forEach(a => {
        animationsForDropdown.value.push({ text: a.name, value: a.id })
      })
      animationTypesForDropdown.value.push({ text: 'Loop', value: 'loop' })
      animationTypesForDropdown.value.push({ text: 'Rebound', value: 'rebound' })
    }

    const showColorPicker = () => {
      dialogOpen.value = true
    }

    bus.$on('selectColor', (color) => {
      settings.value.shapeColor = color
      dialogOpen.value = false
    })

    const changeDimension = () => {
      bus.$emit('changeAnimDimensions')
    }

    const onSelectedAnimationChange = () => {
      const id = settings.value.ddAnimation
      settings.value.selectedAnimationId = id
      settings.value.selectedAnimation = settings.value.animations[settings.value.selectedAnimationId]
      settings.value.shapeWidth = settings.value.selectedAnimation.shapeWidth
      settings.value.shapeHeight = settings.value.selectedAnimation.shapeHeight
      bus.$emit('changeAnimation', settings)
    }

    const onSelectedAnimationTypeChange = () => {
      settings.value.animationType = settings.value.ddAnimationType
      onChangeShapeDimension()
    }

    const onChangeVelocity = () => {
      bus.$emit('changeAnimationVelocity', settings.value.animationVelocity)
      onChangeShapeDimension()
    }

    const onChangeShapeDimension = () => {
      settings.value.selectedAnimation.shapeWidth = settings.value.shapeWidth
      settings.value.selectedAnimation.shapeHeight = settings.value.shapeHeight
      bus.$emit('onRecalculateAnimation')
    }

    onMounted(() => {
      settings.value.animations = defineAnimations(settings)
      settings.value.selectedAnimation = settings.value.animations[0]
      settings.value.ddAnimation = { text: settings.value.selectedAnimation.name, value: settings.value.selectedAnimation.id }
      settings.value.ddAnimationType = { text: 'Loop', value: 'loop' }

      setupControlPanel()
    })

    return {
      settings,
      dialogOpen,
      ColorPickerDialog,
      animationsForDropdown,
      animationTypesForDropdown,
      showColorPicker,
      changeDimension,
      onSelectedAnimationChange,
      onSelectedAnimationTypeChange,
      onChangeVelocity,
      onChangeShapeDimension
    }
  }
}
</script>
