<template>
  <div>
    <video v-if="selectedVideoIdx === 0" width="700" height="428" src="@/assets/Sequence01_1_Scaled.mp4" autoplay muted loop />
    <video v-if="selectedVideoIdx === 1" width="700" height="428" src="@/assets/Sequence02_1_Scaled.mp4" autoplay muted loop />
    <video v-if="selectedVideoIdx === 3" width="700" height="428" src="@/assets/Sequence04_1_Scaled.mp4" autoplay muted loop />
  </div>
</template>

<script>
import { useVideoStore } from '@/store/videoStore'
import { storeToRefs } from 'pinia'

export default {
  setup () {
    const store = useVideoStore()
    const { screenWidth, screenHeight, selectedVideoIdx } = storeToRefs(store)

    return {
      screenWidth,
      screenHeight,
      selectedVideoIdx
    }
  }
}
</script>
